.info-modal-table-data {
    padding: 5px 20px 20px 20px;
}
.info-modal-table-data-head {
    border-bottom: 1px solid lightgray;
}
.welcome-payment-modal-table-data-head{
    border-bottom: 1px solid lightgray;
}
.welcome-payment-modal-table-data{
    padding: 20px 20px 20px 20px;
}
.align-item-self{
    align-self: center;
}
.purchaser-list {
    margin:0 0 1.5em;
    padding:0;
    counter-reset:item;
  }
  .purchaser-list>li {
    margin:0;
    padding:0 0 0 2em;
    text-indent:-2em;
    list-style-type:none;
    counter-increment:item;
  }
.purchaser-list>li:before {
    display:inline-block;
    width:1.5em;
    padding-right:0.5em;
    font-weight:bold;
    text-align:right;
    content:counter(item) ".";
  }
.flex-auto{
    flex: auto;
}